<template>
  <div>
    <v-data-iterator
      :items="dataArray"
      :items-per-page="500"
      :single-expand="false"
      hide-default-footer
    >
      <template v-slot:no-data>
        <app-empty class="xh-[80vh]" :text="emptyText"></app-empty>
      </template>
      <template v-slot:default="{ items }">
        <v-card
          :outlined="item.expand"
          v-for="(item, i) in items"
          :key="i"
          flat
          :color="!item.expand ? '#FAFAFA' : '#F1F1F1'"
          class="xrounded-none xfont-semibold xtext-[15px] xmb-[10px] xborder-none"
        >
          <v-card-title
            :class="{ 'xbg-[#FAFAFA]': item.expand }"
            class="xflex xcursor-pointer xw-full hover:xbg-gray-300 xgap-x-[1em] hover:xbg-opacity-30 xflex-row xjustify-between xtext-[16px] xitems-center"
            @click="() => (item.expand = !item.expand)"
          >
            <div
              :class="[tabletUp ? 'xflex-row' : 'xflex-col']"
              class="xflex xflex-1 xjustify-between xitems-center xw-full"
            >
              <div
                class="xflex xflex-col xjustify-start xitems-start"
                :class="[tabletUp ? 'xw-8/12' : 'xw-full']"
              >
                {{ item.range_from | format("ddd, MMMM D, YYYY") }}
                <chip-pto
                  x-small
                  class="xmt-[5px] xw-fit"
                  v-if="item.summary.is_pto"
                  :approved="item.summary.status == 'approved'"
                ></chip-pto>
                <chip-at
                  x-small
                  class="xmt-[5px] xw-fit"
                  v-if="item.summary.is_added"
                  :approved="item.summary.status == 'approved'"
                ></chip-at>
              </div>
              <div
                class="xflex xflex-1 xgap-x-[0.5em] xflex-row xjustify-start xitems-start"
                :class="[tabletUp ? 'xw-4/12' : 'xw-full']"
              >
                <div
                  class="xw-6/12 xflex xflex-col xjustify-start xitems-stretch"
                >
                  <span class="xleading-[20px] xtext-[12px] xfont-[600]">
                    Work Time
                  </span>
                  <span class="md:xtext-[16px] xtext-[13px]">
                    {{
                      item.summary.works
                        | secToHuman(
                          tabletDown ? " hrs" : " hours",
                          tabletDown ? " mins" : " minutes"
                        )
                    }}
                  </span>
                </div>
                <div
                  class="xw-6/12 xflex xflex-col xjustify-start xitems-stretch"
                >
                  <span class="xleading-[20px] xtext-[12px] xfont-[500]">
                    Break Time
                  </span>
                  <span class="md:xtext-[16px] xtext-[13px]">
                    {{
                      item.summary.breaks
                        | secToHuman(
                          tabletDown ? " hrs" : " hours",
                          tabletDown ? " mins" : " minutes"
                        )
                    }}
                  </span>
                </div>
              </div>
            </div>
            <v-icon color="#000">
              {{ item.expand ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </v-card-title>
          <v-expand-transition>
            <div class="!xp-0 xw-full" v-show="item.expand">
              <ClockingChart
                @edit-block="setEditBlock"
                :has-edit="$can.and('manage_user_time')"
                :has-delete="false"
                :entry="item"
              ></ClockingChart>
            </div>
          </v-expand-transition>
        </v-card>
      </template>
    </v-data-iterator>

    <EditBlockTimeModal
      :block="activeBlock"
      @setChanges="sendToApi"
      @reset-block="activeBlock = null"
      v-model="open_edit_modal"
    ></EditBlockTimeModal>
  </div>
</template>

<script>
import _ from "lodash";
import ClockingChart from "@/views/GlobalComponents/Charts/ClockingChart.vue";
import EditBlockTimeModal from "@/views/GlobalComponents/Modals/EditBlockTimeModal.vue";
export default {
  components: {
    ClockingChart,
    EditBlockTimeModal,
  },
  data() {
    return {
      open_edit_modal: false,
      activeBlock: null,
    };
  },
  props: {
    type: { type: String, default: "daily" },
    emptyText: {
      type: String,
      default: " No records found for the selected date",
    },
    dataArray: { type: Array, default: () => [] },
  },
  methods: {
    setEditBlock(block) {
      this.open_edit_modal = true;
      this.activeBlock = block;
    },
    sendToApi({ payload, reset }) {
      this.$axios
        .post(`api/records/my/edit-blocks`, payload)
        .then(({ data }) => {
          this.appToast(`Successfully submitted.`, "success");
          this.open_edit_modal = false;
          this.fetchSummary(this.selectedYearMonth);
        })
        .catch((err) => reset());
    },
  },
};
</script>

<style lang="scss" sc ClockingChartoped></style>
