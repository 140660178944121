<template>
  <v-card flat min-height="100vh" color="transparent">
    <v-card-title class="xflex xflex-row xjustify-center xitems-center xw-full">
      <Filters
        :value="selectedRange"
        @change="navigate"
        type="weekly"
      ></Filters>
    </v-card-title>
    <v-progress-linear
      indeterminate
      v-if="fetching"
      :height="10"
      class="my-1 xw-full"
      color="primary"
    ></v-progress-linear>
    <Iterators
      empty-text="No records found for the selected week"
      :data-array="records"
      type="weekly"
    ></Iterators>
    <!-- <pre>{{ records }}</pre> -->
  </v-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import Filters from "../components/Filters.vue";
import Iterators from "../components/Iterators.vue";
export default {
  components: { Filters, Iterators },
  data() {
    return {
      fetching: false,
      records: [],
      selectedRange: [
        moment().startOf("week").format("YYYY-MM-DD"),
        moment().endOf("week").format("YYYY-MM-DD"),
      ],
    };
  },
  watch: {
    "$route.params": {
      handler: function (val) {
        this.selectedRange = val
          ? [val.start, val.end]
          : [
              moment().startOf("week").format("YYYY-MM-DD"),
              moment().endOf("week").format("YYYY-MM-DD"),
            ];
        val && this.fetchSummary(val);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    selectedId() {
      return this.$route.params.id;
    },
  },
  methods: {
    navigate(val) {
      this.$router
        .push({
          name: "app-user-record-weekly",
          params: { id: this.selectedId, start: val[0], end: val[1] },
        })
        .catch(() => {});
    },
    sortBy(sortFn) {
      this.sortDesc = !this.sortDesc;
      this.sortFn = sortFn;
    },
    fetchSummary(range) {
      this.fetching = true;
      this.$axios
        .get(
          `api/user-records/${this.selectedId}/weekly/${range.start}/${range.end}`
        )
        .then(({ data }) => {
          this.records = data.summaries;
        })
        .finally(() => (this.fetching = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
