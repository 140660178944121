<template>
  <v-sheet
    class="xflex xw-full md:xflex-row xflex-col xgap-[3px] xjustify-between xitems-center"
  >
    <div
      :class="[mdUp ? 'xflex-row' : 'xflex-col']"
      class="controller xflex md:xgap-x-[10px] xgap-x-[5px] xjustify-start xitems-center xw-full"
    >
      <KirbyMonthPicker
        :value="selectedValue"
        no-future
        :class="[mdUp ? '' : 'xw-full']"
        @change="emitChange"
        v-if="type === 'monthly'"
      ></KirbyMonthPicker>
      <KirbyWeeklyPicker
        :value="selectedValue"
        no-future
        :class="[mdUp ? '' : 'xw-full']"
        @change="emitChange"
        v-else-if="type === 'weekly'"
      ></KirbyWeeklyPicker>
      <KirbyDatePicker
        :value="selectedValue"
        no-future
        :class="[mdUp ? '' : 'xw-full']"
        @change="emitChange"
        v-else-if="type === 'daily'"
      ></KirbyDatePicker>
      <KirbyRangePicker
        :value="selectedValue"
        no-future
        :class="[mdUp ? '' : 'xw-full']"
        @change="emitChange"
        v-else-if="type === 'custom'"
      ></KirbyRangePicker>
      <div
        class="xflex flex-row xgap-[5px] md:xmy-0 xmy-[10px] typer"
        v-if="laptopUp"
      >
        <btn-tooltip
          tip="Filter by monthly"
          :color="type === 'monthly' ? '#7A38A3' : 'base_100'"
          :disabled="fetchingCsv || type === 'monthly'"
          :loading="fetchingCsv"
          :outlined="type !== 'monthly'"
          @click="setFilter('monthly')"
          class="text-none"
        >
          Month
        </btn-tooltip>
        <btn-tooltip
          tip="Filter by weekly"
          :color="type === 'weekly' ? '#7A38A3' : 'base_100'"
          :disabled="fetchingCsv || type === 'weekly'"
          :loading="fetchingCsv"
          :outlined="type !== 'weekly'"
          @click="setFilter('weekly')"
          class="text-none"
        >
          Week
        </btn-tooltip>
        <btn-tooltip
          tip="Filter by date"
          :color="type === 'daily' ? '#7A38A3' : 'base_100'"
          :disabled="fetchingCsv || type === 'daily'"
          :loading="fetchingCsv"
          :outlined="type !== 'daily'"
          @click="setFilter('daily')"
          class="text-none"
        >
          Day
        </btn-tooltip>
        <btn-tooltip
          tip="Custom range filter"
          :color="type === 'custom' ? '#7A38A3' : 'base_100'"
          :disabled="fetchingCsv || type === 'custom'"
          :loading="fetchingCsv"
          :outlined="type !== 'custom'"
          @click="setFilter('custom')"
          class="text-none"
        >
          Custom
        </btn-tooltip>
      </div>
      <div
        class="xflex xflex-row xjustify-end xitems-center xgap-[5px] xw-full !xmy-[5px]"
        v-else
      >
        <btn-range-filter
          @monthly="setFilter('monthly')"
          @weekly="setFilter('weekly')"
          @daily="setFilter('daily')"
          @custom="setFilter('custom')"
          :type="type"
        >
        </btn-range-filter>
        <v-spacer></v-spacer>
        <btn-list
          icon="mdi-chevron-down"
          btn-color="primary"
          text-color="primary"
          outlined
        >
          <template v-slot:label>
            <v-icon small color="primary">mdi-form-dropdown</v-icon>
          </template>
          <v-list>
            <v-list-item
              v-if="$can.and('manage_user_ptos')"
              @click="add_pto_modal = true"
            >
              <v-list-item-title>Add PTO</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$can.and('manage_user_time')"
              @click="add_time_modal = true"
            >
              <v-list-item-title>Add Time</v-list-item-title>
            </v-list-item>
          </v-list>
        </btn-list>
        <btn-export
          v-if="hasDownload"
          :api-csv="getFileUrl('csv')"
          :api-pdf="getFileUrl('pdf')"
          :api-jpg="getFileUrl('jpg')"
          :filename="fileName"
        ></btn-export>
      </div>
    </div>
    <div
      v-if="laptopUp"
      class="xflex md:xgap-[0.5em] xgap-x-0 xgap-y-[0.5em] md:xflex-row xflex-col xjustify-end xitems-center"
    >
      <v-spacer v-if="mdUp"></v-spacer>
      <btn-tooltip
        v-if="$can.and('manage_user_ptos')"
        tip="Add Paid Time Off"
        color="primary"
        :block="smDown"
        :disabled="false"
        :loading="false"
        @click="add_pto_modal = true"
        class="text-none"
      >
        <v-icon left> mdi-plus </v-icon> Add PTO
      </btn-tooltip>
      <btn-tooltip
        tip="Add Time"
        color="primary"
        v-if="$can.and('manage_user_time')"
        :block="smDown"
        :disabled="false"
        :loading="false"
        @click="add_time_modal = true"
        class="text-none"
      >
        <v-icon left> mdi-plus </v-icon> Add Time
      </btn-tooltip>
      <btn-export
        v-if="hasDownload"
        :api-csv="getFileUrl('csv')"
        :api-pdf="getFileUrl('pdf')"
        :api-jpg="getFileUrl('jpg')"
        :filename="fileName"
      ></btn-export>
    </div>

    <AddTimeModal
      disable-select-user
      v-model="add_time_modal"
      :user="selectedUser"
    ></AddTimeModal>

    <AddPTOModal
      disable-select-user
      v-model="add_pto_modal"
      :user="selectedUser"
    ></AddPTOModal>
  </v-sheet>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import KirbyWeeklyPicker from "@/views/GlobalComponents/Forms/KirbyWeeklyPicker.vue";
import KirbyDatePicker from "@/views/GlobalComponents/Forms/KirbyDatePicker.vue";
import KirbyMonthPicker from "@/views/GlobalComponents/Forms/KirbyMonthPicker.vue";
import KirbyRangePicker from "@/views/GlobalComponents/Forms/KirbyRangePicker.vue";
import AddPTOModal from "@/views/GlobalComponents/Modals/AddPTOModal.vue";
import AddTimeModal from "@/views/GlobalComponents/Modals/AddTimeModal.vue";

export default {
  props: {
    type: { type: String, default: "daily" },
    value: { type: [Object, Array, String], default: undefined },
    hasDownload: { type: Boolean, default: true },
  },
  data() {
    return {
      fetchingCsv: false,
      selectedValue: null,
      selectedUser: null,
      selectedId: null,
      add_time_modal: false,
      add_pto_modal: false,
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.selectedValue = val;
      },
      immediate: true,
      deep: true,
    },
    selectedValue: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
      deep: true,
    },
    "$route.params.id": {
      handler: function (val) {
        this.selectedId = val;
        this.selectedUser =
          _.cloneDeep(this.allusers).find((i) => i.id == val) || null;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("workspace", ["allusers"]),
    ...mapGetters(["user"]),
    fileName() {
      let name = this.selectedUser ? this.selectedUser.real_name : "User";
      if (this.type === "monthly") return `${name}-Monthly-${this.value}`;
      else if (this.type === "weekly")
        return `${name}-Weekly-${this.value[0]}-${this.value[1]}`;
      else if (this.type === "daily") return `${name}-Daily-${this.value}`;
      else return `${name}-Custom-${this.value[0]}-${this.value[1]}`;
    },
  },
  methods: {
    getFileUrl(file) {
      if (this.type === "monthly")
        return `api/user-records/${this.selectedId}/monthly/${this.value}/null/${file}`;
      else if (this.type === "weekly")
        return `api/user-records/${this.selectedId}/weekly/${this.value[0]}/${this.value[1]}/${file}`;
      else if (this.type === "daily")
        return `api/user-records/${this.selectedId}/daily/${this.value}/null/${file}`;
      else
        return `api/user-records/${this.selectedId}/custom/${this.value[0]}/${this.value[1]}/${file}`;
    },
    emitChange(val) {
      this.$emit("change", val);
    },
    setFilter(type) {
      if (type === "monthly")
        this.$router.push({
          name: "app-user-record-monthly",
          params: {
            id: this.selectedId,
            year_month: moment().format("YYYY-MM"),
          },
        });
      else if (type === "weekly")
        this.$router.push({
          name: "app-user-record-weekly",
          params: {
            id: this.selectedId,
            start: moment().startOf("week").format("YYYY-MM-DD"),
            end: moment().endOf("week").format("YYYY-MM-DD"),
          },
        });
      else if (type === "daily")
        this.$router.push({
          name: "app-user-record-daily",
          params: { id: this.selectedId, date: moment().format("YYYY-MM-DD") },
        });
      else
        this.$router.push({
          name: "app-user-record-custom",
          params: {
            id: this.selectedId,
            start: moment().subtract(1, "day").format("YYYY-MM-DD"),
            end: moment().format("YYYY-MM-DD"),
          },
        });
    },
  },
  components: {
    KirbyWeeklyPicker,
    KirbyDatePicker,
    KirbyMonthPicker,
    KirbyRangePicker,
    AddPTOModal,
    AddTimeModal,
  },
};
</script>

<style lang="css">
.typer .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background: #7a38a3 !important;
  color: #fff !important;
}
</style>
